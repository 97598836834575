<template>
  <div>
    <el-container>
      <el-aside :width="collapse ? '65px' : '201px'">
        <div
          class="logo"
          @click="
            () => {
              this.$router.push({ name: 'HomeIndex' });
            }
          "
        >
          <img
            src="@/assets/logo.png"
            width="60"
            height="60"
            style="float: left; margin-right: 8px"
          />
          <transition name="el-zoom-in-center">
            <div style="line-height: 60px" v-show="!collapse">
              {{ userInfo.companyName }}
            </div>
          </transition>
        </div>
        <div style="overflow: auto">
          <el-menu
            :collapse-transition="false"
            class="menu"
            router
            :collapse="collapse"
            :background-color="variable.backgroundColor"
            :text-color="variable.texColor"
            :active-text-color="variable.activeTextColor"
            :unique-opened="true"
            :default-active="activeMenu"
          >
            <el-submenu
              :index="item.name"
              v-for="item in menu"
              :key="item.name"
            >
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                :route="{ name: child.name }"
                :index="child.name"
                v-for="child in item.children"
                :key="child.name"
                >{{ child.title }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-header height="60px" class="header">
          <span class="header-icon-left" @click="collapse = !collapse">
            <i :class="[collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"></i>
          </span>
          <div style="float: left; margin-left: 16px">
            <el-menu
              router
              mode="horizontal"
              :background-color="variable.backgroundColor"
              :text-color="variable.texColor"
              :active-text-color="variable.activeTextColor"
              :default-active="activeMenu"
            >
              <el-submenu
                :index="item.name"
                v-for="item in nav"
                :key="item.name"
              >
                <template slot="title">
                  <span>{{ item.title }}</span>
                </template>
                <el-menu-item
                  :route="{ name: child.name }"
                  :index="child.name"
                  v-for="child in item.children"
                  :key="child.name"
                  >{{ child.title }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>

          <div style="float: right">
            <user-set></user-set>
          </div>
          <span class="header-icon-right">
            <el-tooltip content="同步基础资料">
              <i class="el-icon-refresh" @click="refreshBaseInfo"></i>
            </el-tooltip>
          </span>
        </el-header>
        <el-main>
          <router-tab :tabs="tabs" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import variable from "./variable.scss";
import { mapState } from "vuex";
import UserSet from "./UserSet";
export default {
  name: "BaseLayout",
  components: {
    UserSet,
  },
  data() {
    return {
      variable: variable,
      collapse: false,
      tabs: [
        {
          to: {
            name: "HomeIndex",
          },
        },
      ],
    };
  },
  computed: {
    ...mapState(["menu", "nav", "userInfo"]),
    activeMenu() {
      return this.$route.name == "Home" ? "" : this.$route.name;
    },
  },
  methods: {
    refreshBaseInfo() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("Sys/GetBaseInfo")
        .then((r) => {
          for (let i in r) {
            this.$store.commit("initBaseInfo", {
              info: i,
              array: r[i],
            });
          }
          this.$message.success("同步成功");
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang='scss'  scoped>
@import "./variable.scss";
.logo {
  height: 60px;
  background-color: $backgroundColor;
  color: $texColor;
  cursor: pointer;
  :hover {
    color: $activeTextColor;
  }
}
.menu {
  &:not(.el-menu--collapse) {
    width: 200px;
  }
  height: calc(100vh - 60px);
}

.header {
  background-color: $backgroundColor;
  color: $texColor;
  line-height: 60px;
  padding: 0;

  .header-icon-left {
    float: left;
    font-size: xx-large;
    cursor: pointer;
    :hover {
      color: $activeTextColor;
    }
  }
  .header-icon-right {
    float: right;
    margin-right: 8px;
    font-size: xx-large;
    cursor: pointer;
    :hover {
      color: $activeTextColor;
    }
  }
}

.el-main {
  padding: 0;
}
</style>
