import Vue from 'vue'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

Vue.prototype.$moment = moment;


/**
 * 格式化日期
 */
Vue.filter('datetime', function (value, format = 'YYYY-MM-DD') {
    if (!value) {
        return '-';
    }
    if (value == '-') {
        return '-'
    }
    return moment(value).format(format);
});
/**
 * 数字千分位
 */
const thousandsFormat = function (num, fixed = 8) {

    if (num == null || num == undefined || num == '' || isNaN(num)) {
        return '-'
    }

    if (typeof num !== 'number') {
        num = Number(num)
    }
    return num.toLocaleString('zh', {
        maximumFractionDigits: fixed,
    })

};
Vue.prototype.thousandsFormat = thousandsFormat;
Vue.filter('thousands', thousandsFormat);

/**
 * 金额汉字大写
 */
Vue.filter('money', function (amount) {
    if (amount == null || amount == undefined || amount == '' || isNaN(amount)) {
        return '空';
    }
    if (typeof amount !== 'number') {
        amount = Number(amount)
    }
    const fraction = ['角', '分'];
    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    const unit = [
        ['元', '万', '亿'],
        ['', '拾', '佰', '仟'],
    ];
    let num = Math.abs(amount);
    let s = '';
    fraction.forEach((item, index) => {
        s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '');
    });
    s = s || '整';
    num = Math.floor(num);
    for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = '';
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
            p = digit[num % 10] + unit[1][j] + p;
            num = Math.floor(num / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    }

    return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
})

/**
 * 
 * 格式化金额
 */
const amountFormat = function (amount) {
    if (amount == null || amount == undefined || amount == '' || isNaN(amount)) {
        return 0;
    }
    if (typeof amount !== 'number') {
        amount = Number(amount)
    }
    return Number(amount.toFixed(2))
}
Vue.prototype.amountFormat = amountFormat;
Vue.filter('amount', amountFormat);