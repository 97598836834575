<template>
  <div>
    <el-menu
      @select="handleCommand"
      mode="horizontal"
      background-color="#000"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-submenu index="1">
        <template slot="title">{{ userInfo.operatorName }}</template>
        <el-menu-item index="modifPwd">修改密码</el-menu-item>
        <el-menu-item index="loginOut">退出登录</el-menu-item>
      </el-submenu>
    </el-menu>

    <div>
      <modif-pwd :item="modifItem"></modif-pwd>
    </div>
  </div>
</template>

<script>
import ModifPwd from "./ModifPwd";
import { mapState } from "vuex";
export default {
  name: "UserSet",
  components: {
    ModifPwd,
  },
  data() {
    return {
      modifItem: {},
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "loginOut":
          this.loginOut();
          break;
        case "modifPwd":
          this.modifItem = {};
          break;
      }
    },
    loginOut() {
      sessionStorage.clear();
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.el-submenu >>> .el-submenu__title {
  border-bottom-color: transparent !important;
}
</style>