import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts'

import plTable from 'pl-table'
// import 'pl-table/themes/index.css' 
// import 'pl-table/themes/plTableStyle.css' 
Vue.use(plTable);


Vue.prototype.$echarts = echarts


import Element from 'element-ui'
import './themes/theme.scss'
Vue.use(Element, {
  size: 'small'
});

import RouterTab from 'vue-router-tab'
Vue.use(RouterTab)

import http from '@/ajax.js'
Vue.prototype.$get = http.get;
Vue.prototype.$post = http.post;

import '@/common/directive.js'
import '@/common/filter.js'



import hasPermission from '@/common/hasPermission';
Vue.prototype.hasPermission = hasPermission;

import global from '@/global.js';
Vue.prototype.$g = global;

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

if (sessionStorage['token']) {
  store.dispatch('GetUserInfo');
  store.dispatch('GetBaseInfo');
  store.dispatch('AddMenus');
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')