<template>
  <div>
    <div class="action-btn" v-show="!isFullscreen">
      <el-tooltip content="全屏" effect="light">
        <el-button type="text" size="medium" @click="screenfull">
          <i class="el-icon-full-screen"></i>
        </el-button>
      </el-tooltip>

      <el-tooltip content="关闭" effect="light">
        <el-button type="text" size="medium" @click="close">
          <i class="el-icon-close"></i>
        </el-button>
      </el-tooltip>
    </div>
    <router-view />
  </div>
</template>
<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      isFullscreen: false
    };
  },
  mounted() {
    screenfull.on("change", () => {
      this.isFullscreen = screenfull.isFullscreen;
    });
    this.$nextTick(() => {
      this.screenfull();
    });
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    screenfull() {
      screenfull.request();
    }
  }
};
</script>

<style scoped>
.action-btn {
  position: absolute;
  right: 8px;
  z-index: 100;
}
</style>