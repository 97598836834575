import Home from '@/views/layout/BasicLayout'
import Full from '@/views/layout/FullScreen'
const router = [{
    path: '/findbill',
    name: 'FindBill',
    component: Home,
    meta: {
        title: '业务查找',
    },
    children: [{
        path: 'GoodsPlanList',
        name: "GoodsPlanList",
        meta: {
            title: '采购计划列表',
            auth: 'GoodsPlanList',
        },
        component: () => import('@/views/GoodsPlan/List')
    }, {
        path: 'purchaselist',
        name: "PurchaseList",
        meta: {
            title: '采购列表',
            auth: 'PurchaseList',
        },
        component: () => import('@/views/purchase/List')
    }, {
        path: 'assembledlist',
        name: "AssembledList",
        meta: {
            title: '组装列表',
            auth: 'AssembledList',
        },
        component: () => import('@/views/assembled/List')
    }, {
        path: 'allottedlist',
        name: "AllottedList",
        meta: {
            title: '调配列表',
            auth: 'AllottedList',
        },
        component: () => import('@/views/allotted/List')
    }, {
        path: 'inventorylist',
        name: "InventoryList",
        meta: {
            title: '盘点列表',
            auth: 'InventoryList',
        },
        component: () => import('@/views/inventory/List')
    }, {
        path: 'paymentlist',
        name: "PaymentList",
        meta: {
            title: '付款列表',
            auth: 'PaymentList',
        },
        component: () => import('@/views/payment/List')
    }, {
        path: 'receiptlist',
        name: "ReceiptList",
        meta: {
            title: '收款列表',
            auth: 'ReceiptList',
        },
        component: () => import('@/views/receipt/List')
    }, {
        path: 'borrowerlist',
        name: "BorrowerList",
        meta: {
            title: '借还款列表',
            auth: 'BorrowerList',
        },
        component: () => import('@/views/borrower/List')
    }, {
        path: 'expenselist',
        name: "ExpenseList",
        meta: {
            title: '项目费用列表',
            auth: 'ExpenseList',
        },
        component: () => import('@/views/expense/List')
    }, {
        path: 'laborlist',
        name: "LaborList",
        meta: {
            title: '人工费用列表',
            auth: 'LaborList',
        },
        component: () => import('@/views/labor/List')
    }]
}, {
    path: '/goodsreport',
    name: 'GoodsReport',
    component: Home,
    meta: {
        title: '物资报表',
    },
    children: [{
        path: "GoodsPlanExecute",
        name: "GoodsPlanExecute",
        meta: {
            title: '采购计划执行',
            auth: 'GoodsPlanExecute',
        },
        component: () => import('@/views/GoodsPlan/PlanExecute')
    }, {
        path: "storegoods",
        name: "StoreGoods",
        meta: {
            title: '仓库收发存',
            auth: 'StoreGoods',
        },
        component: () => import('@/views/report/goods/StoreGoods')
    }, {
        path: 'projectgoods',
        name: 'ProjectGoods',
        meta: {
            title: '项目物资消耗',
            auth: 'ProjectGoods',
        },
        component: () => import('@/views/report/goods/ProjectGoods')
    }, {
        path: 'goodsflow',
        name: "GoodsFlow",
        meta: {
            title: '物资流水帐',
            auth: 'GoodsFlow',
        },
        component: () => import('@/views/report/goods/GoodsFlow')
    }, {
        path: 'supplierpurchase',
        name: "SupplierPurchase",
        meta: {
            title: '供应商供货明细',
            auth: 'SupplierPurchase',
        },
        component: () => import('@/views/report/goods/SupplierPurchase')
    }]
}, {
    path: '/expensereport',
    name: 'ExpenseReport',
    component: Home,
    meta: {
        title: '费用报表',
    },
    children: [{
        path: "projectexpense",
        name: "ProjectExpense",
        meta: {
            title: '项目费用汇总',
            auth: 'ProjectExpense',
        },
        component: () => import('@/views/report/expense/ProjectExpense')
    }, {
        path: 'expensedetaillist',
        name: 'ExpenseDetailList',
        meta: {
            title: '项目费用明细',
            auth: 'ExpenseDetailList',
        },
        component: () => import('@/views/report/expense/ProjectExpenseDetail')
    }, {
        path: "projectlabor",
        name: "ProjectLabor",
        meta: {
            title: '人工费用汇总',
            auth: 'ProjectLabor',
        },
        component: () => import('@/views/report/expense/ProjectLabor')
    }, {
        path: 'labordetaillist',
        name: 'LaborDetailList',
        meta: {
            title: '人工费用明细',
            auth: 'LaborDetailList',
        },
        component: () => import('@/views/report/expense/ProjectLaborDetail')
    }]
}, {
    path: '/capitalreport',
    name: 'CapitalReport',
    component: Home,
    meta: {
        title: '资金报表',
    },
    children: [{
        path: "paymentreport",
        name: "PaymentReport",
        meta: {
            title: '应付汇总',
            auth: 'PaymentReport',
        },
        component: () => import('@/views/report/capital/PaymentReport')
    }, {
        path: "paymentdetailreport",
        name: "PaymentDetailReport",
        meta: {
            title: '应付明细',
            auth: 'PaymentDetailReport',
        },
        component: () => import('@/views/report/capital/PaymentDetailReport')
    }, {
        path: "ReceiptReport",
        name: "ReceiptReport",
        meta: {
            title: '应收汇总',
            auth: 'ReceiptReport',
        },
        component: () => import('@/views/report/receipt/List.vue')
    }, {
        path: "ReceiptDetailReport",
        name: "ReceiptDetailReport",
        meta: {
            title: '应收明细',
            auth: 'ReceiptDetailReport',
        },
        component: () => import('@/views/report/receipt/Detail.vue')
    }, {
        path: 'projectreceiptpayment',
        name: 'ProjectReceiptPayment',
        meta: {
            title: '项目收支汇总',
            auth: 'ProjectReceiptPayment',
        },
        component: () => import('@/views/report/capital/ProjectReceiptPayment/Summary')
    }, {
        path: 'projectreceiptpaymentdetailbybill',
        name: 'ProjectReceiptPaymentDetailByBill',
        meta: {
            title: '项目收支明细',
            auth: 'ProjectReceiptPaymentDetailByBill',
        },
        component: () => import('@/views/report/capital/ProjectReceiptPayment/DetailByBill')
    }, {
        path: 'projectreceiptpaymentdetailbydetail',
        name: 'ProjectReceiptPaymentDetailByDetail',
        meta: {
            title: '项目收支明细',
            auth: 'ProjectReceiptPaymentDetailByDetail',
        },
        component: () => import('@/views/report/capital/ProjectReceiptPayment/DetailByDetail')
    }, {
        path: 'borrowerflow',
        name: "BorrowerFlow",
        meta: {
            title: '借还款台账',
            auth: 'BorrowerFlow',
        },
        component: () => import('@/views/report/capital/EmployeeFlow')
    }]
}, {
    path: '/projectreport',
    name: 'ProjectReport',
    component: Home,
    meta: {
        title: '项目报表',
    },
    children: [{
        path: "projectsynthesize",
        name: "ProjectSynthesize",
        meta: {
            title: '项目综合',
            auth: 'ProjectSynthesize',
        },
        component: () => import('@/views/report/project/synthesize/Summary')
    }, {
        path: "projectsynthesizedetail",
        name: "ProjectSynthesizeDetail",
        meta: {
            title: '项目明细',
            auth: 'ProjectSynthesizeDetail',
        },
        component: () => import('@/views/report/project/synthesize/Detail')
    }]
}, {
    path: '/summaryreport',
    name: 'SummaryReport',
    component: Full,
    meta: {
        title: '其他报表',
    },
    children: [{
        path: 'dataview',
        name: "DataView",
        meta: {
            title: '数据大屏',
            auth: 'DataView',
        },
        component: () => import('@/views/report/DataView')
    }]
}];

export default router