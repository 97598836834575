<template>
  <el-dialog title="修改密码" :visible.sync="visible" append-to-body v-dialogDrag width="30%">
    <el-form :model="pwdForm" status-icon :rules="rules" ref="pwdForm" label-width="70px">
      <el-form-item label="原密码" prop="oldPwd">
        <el-input placeholder="原密码" v-model="pwdForm.oldPwd" type="password" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPwd">
        <el-input placeholder="新密码" v-model="pwdForm.newPwd" type="password" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="modifPwd">
        <el-input placeholder="确认密码" v-model="pwdForm.modifPwd" type="password" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleOk" :loading="loading">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      pwdForm: {
        oldPwd: "",
        newPwd: "",
        modifPwd: ""
      },
      rules: {
        oldPwd: [{ required: true, message: "旧密码不能为空" }],
        newPwd: [{ required: true, message: "新密码不能为空" }],
        modifPwd: [{ validator: this.compareToFirstPassword }]
      }
    };
  },
  watch: {
    item: {
      handler: function() {
        if (this.$refs["pwdForm"]) {
          this.$refs["pwdForm"].resetFields();
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    handleOk() {
      this.$refs["pwdForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$post("Operator/ModifPassword", this.pwdForm)
            .then(r => {
              this.$message.success("修改成功，请记住新密码！");
              this.visible = false;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style>
</style>